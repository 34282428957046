<template lang="pug">
.vc-common-fb-messenger
  a.service-icon.-has-object(
    :href="fbMessengerMMeLink"
    target="_blank"
    :style="{ bottom: bottom }"
  )
    //img(:src="FbmessengerIcon")
    object.kolnet-svg-icon(
      type="image/svg+xml"
      :data="FbmessengerIcon"
    )
</template>

<script>
import FbmessengerIcon from '../../../images/msg.svg'
import InstagramBarResponseMixin from '../mixins/instagram_bar_response_mixin.js'

export default {
  // components: {},

  mixins: [InstagramBarResponseMixin],

  props: {
    fbMessengerMMeLink: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      bottom: null
    }
  },

  computed: {
    FbmessengerIcon() {
      return FbmessengerIcon
    }
  },
  // created() {},
  // created() {},
  // mounted() {},

  methods: {
    onScrollDownForInstagram() {
      this.bottom = null
    },

    onScrollUpForInstagram() {
      this.bottom = `${this.instagramBarHeight + 16}px`
    }
  }
}
</script>
