<template lang="pug">
.vc-line-at-link
  a.service-icon(
    :href="lineAtLink"
    target="_blank"
    :style="{ bottom: bottom }"
  )
    img(:src="LineAtIcon")
</template>

<script>
import LineAtIcon from '../../../images/line-at-icon.png'
import InstagramBarResponseMixin from '../mixins/instagram_bar_response_mixin.js'

export default {
  // components: {},

  mixins: [InstagramBarResponseMixin],

  props: {
    lineAtLink: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      bottom: null
    }
  },

  computed: {
    LineAtIcon() {
      return LineAtIcon
    }
  },
  // created() {},
  // created() {},
  // mounted() {},

  methods: {
    onScrollDownForInstagram() {
      this.bottom = null
    },

    onScrollUpForInstagram() {
      this.bottom = `${this.instagramBarHeight + 16}px`
    }
  }
}
</script>

<style lang="sass" scoped>
.vc-line-at-link
  padding: 3.4px
  .service-icon
    border-radius: var(--width)
    background-color: white
    padding: 2px
    box-shadow: 0 0 .32rem rgba(black, .6)
    scale: 0.97
</style>
